var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var YearView = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\nheight: auto;\nmin-height: 500px;\npadding: 10px 0px;\ncursor: all-scroll;\nbackground-image: repeating-linear-gradient(\n    90deg,\n    transparent 1px,\n    transparent 60px,\n    #c5cad2 1px,\n    #c5cad2 61px\n  );\n"], ["\n\nheight: auto;\nmin-height: 500px;\npadding: 10px 0px;\ncursor: all-scroll;\nbackground-image: repeating-linear-gradient(\n    90deg,\n    transparent 1px,\n    transparent 60px,\n    #c5cad2 1px,\n    #c5cad2 61px\n  );\n"])));
export var MonthList = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\nmargin-top:30px;\ndisplay: flex;\nwidth: 720px;\njustify-content: space-between;\n\nspan{\n    font-size: 11px;\n    width: 60px;\n    text-align:center;\n    color:#19406c;\n}\n"], ["\nmargin-top:30px;\ndisplay: flex;\nwidth: 720px;\njustify-content: space-between;\n\nspan{\n    font-size: 11px;\n    width: 60px;\n    text-align:center;\n    color:#19406c;\n}\n"])));
export var ProjectsContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\nmin-height:500px;\ncursor: all-scroll;\nscroll-behavior: smooth;\n&::-webkit-scrollbar{\n  display: none;\n}\noverflow:scroll;\n\nmargin-bottom:20px;\n\n"], ["\nmin-height:500px;\ncursor: all-scroll;\nscroll-behavior: smooth;\n&::-webkit-scrollbar{\n  display: none;\n}\noverflow:scroll;\n\nmargin-bottom:20px;\n\n"])));
export var ProjectCart = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\nmargin-top:1rem;\nmin-height: 52px;\nmin-width: 200px;\nborder-left: 10px solid #1479cc;\nmax-width: none;\nmax-width: initial;\ndisplay: -webkit-inline-box;\ndisplay: inline-flex;\nbackground-color: white;\n-webkit-box-shadow: rgb(99 99 99 / 20%) 0 2px 8px 0;\nbox-shadow: rgb(99 99 99 / 20%) 0 2px 8px 0;\npadding: 8px;\n-webkit-box-orient: vertical;\n-webkit-box-direction: normal;\nflex-direction: column;\ndisplay:block\n"], ["\nmargin-top:1rem;\nmin-height: 52px;\nmin-width: 200px;\nborder-left: 10px solid #1479cc;\nmax-width: none;\nmax-width: initial;\ndisplay: -webkit-inline-box;\ndisplay: inline-flex;\nbackground-color: white;\n-webkit-box-shadow: rgb(99 99 99 / 20%) 0 2px 8px 0;\nbox-shadow: rgb(99 99 99 / 20%) 0 2px 8px 0;\npadding: 8px;\n-webkit-box-orient: vertical;\n-webkit-box-direction: normal;\nflex-direction: column;\ndisplay:block\n"])));
export var ProjectCartHeader = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\ndisplay:flex;\njustify-content:space-between;\ncolor:red;\nfont-weight:bold;\nmargin-top: 5px;\ncursor: pointer;\n.card-title {\n  word-break: break-word;\n}\n"], ["\ndisplay:flex;\njustify-content:space-between;\ncolor:red;\nfont-weight:bold;\nmargin-top: 5px;\ncursor: pointer;\n.card-title {\n  word-break: break-word;\n}\n"])));
export var ProjectCartBody = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\nwidth:350px;\nmargin-top:16px;\n\n.documents-count {\n  color: ", ";\n  font-size: 14px;\n  line-height: 120%;\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n"], ["\nwidth:350px;\nmargin-top:16px;\n\n.documents-count {\n  color: ", ";\n  font-size: 14px;\n  line-height: 120%;\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.blueFonce;
});
// #dce6ef #8c98a7
export var Input = styled.input(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\nappearance: none;\nwidth: 100%;\nheight: 15px;\nbackground: #dce6ef;\nborder-radius: 10px;\noutline: none;\nopacity: 0.7;\n-webkit-transition: opacity 0.2s;\ntransition: opacity 0.2s;\ncursor:pointer;\n\n&:focus {\n  outline: none;\n}\n\n\n\n&::-webkit-slider-thumb {\n  position: relative;\n  appearance: none;\n  height:21px;\n  width:21px;\n  background: #fff;\n  border-radius: 50%;\n  border: 1px solid #8c98a7;\n\n  &::after{\n    content: '", "' ;\n    width:15px;\n    height:5px;\n    color:red;\n  }\n}\n\n&::-webkit-slider-thumb::before{\n  content: '", "' ;\n  width:15px;\n  height:5px;\n  color:red;\n  background: red;\n}\n\n\n"], ["\nappearance: none;\nwidth: 100%;\nheight: 15px;\nbackground: #dce6ef;\nborder-radius: 10px;\noutline: none;\nopacity: 0.7;\n-webkit-transition: opacity 0.2s;\ntransition: opacity 0.2s;\ncursor:pointer;\n\n&:focus {\n  outline: none;\n}\n\n\n\n&::-webkit-slider-thumb {\n  position: relative;\n  appearance: none;\n  height:21px;\n  width:21px;\n  background: #fff;\n  border-radius: 50%;\n  border: 1px solid #8c98a7;\n\n  &::after{\n    content: '", "' ;\n    width:15px;\n    height:5px;\n    color:red;\n  }\n}\n\n&::-webkit-slider-thumb::before{\n  content: '", "' ;\n  width:15px;\n  height:5px;\n  color:red;\n  background: red;\n}\n\n\n"])), function (props) { return props.year == 2010 || props.year == 2030 ? '' : props.year; }, function (props) { return props.year == 2010 || props.year == 2030 ? '' : props.year; });
export var IndicatorContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\nposition: relative;\nheight: 22px;\ncolor;", ";\nfont-size:14px;\n"], ["\nposition: relative;\nheight: 22px;\ncolor;", ";\nfont-size:14px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.blueFonce;
});
export var Indicatore = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\nposition: absolute;\nbottom: 0;\n"], ["\nposition: absolute;\nbottom: 0;\n"])));
export var YearsContainer = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\ndisplay:flex;\njustify-content:space-between;\ncolor;", ";\nfont-size:14px;\n"], ["\ndisplay:flex;\njustify-content:space-between;\ncolor;", ";\nfont-size:14px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.blueFonce;
});
export var SlideContainer = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\ndisplay: flex;\ngap: 10px;\nalign-items: center;\n\n & span {\n  font-size:12px;\n }\n"], ["\ndisplay: flex;\ngap: 10px;\nalign-items: center;\n\n & span {\n  font-size:12px;\n }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
