var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import ProjectsContext from "../../../../application/Context/ProjectsContext";
import MarketsContext from "../../../../application/Context/MarketsContext";
import { ProjectsCard } from "../../advanced-search-components/Cards/ProjectsCard";
import { Loading } from "../../shared-components/Loading/Loading";
import { NotFound } from "../../shared-components/NotFound";
import { motion } from "framer-motion";
export var List = function (_a) {
    var _b;
    var application = _a.application;
    var t = useTranslation(["common"]).t;
    var context = application == "markets"
        ? useContext(MarketsContext)
        : useContext(ProjectsContext);
    var projects = context.projects, categories = context.categories, projectsTypes = context.projectsTypes, projectsIsLoading = context.projectsIsLoading, filtersIsLoading = context.filtersIsLoading, handleCategoryChange = context.handleCategoryChange, handleProjectTypeChange = context.handleProjectTypeChange;
    var langCode = ((_b = document.getElementById("language-code")) === null || _b === void 0 ? void 0 : _b.value) || "en";
    var params = new URLSearchParams(window.location.search);
    React.useEffect(function () {
        if ((params.has("company_category") || params.has("project_type")) && !filtersIsLoading) {
            if (params.has("company_category")) {
                var company_category = categories.filter(function (e) { return e.id == params.get("company_category"); });
                if (company_category.length > 0) {
                    handleCategoryChange([{
                            label: langCode == "fr" ? company_category[0].nom_fr : company_category[0].nom_en,
                            value: company_category[0].id
                        }]);
                }
            }
            if (params.has("project_type")) {
                var project_type = projectsTypes.filter(function (e) { return e.id == params.get("project_type"); });
                if (project_type.length > 0) {
                    handleProjectTypeChange([{
                            label: project_type[0].nom,
                            value: project_type[0].id
                        }]);
                }
                document.title = "".concat(project_type[0].nom, " - ").concat(t("Markets"));
            }
            window.history.replaceState(null, "", window.location.href.split("?")[0]);
        }
    }, [filtersIsLoading]);
    return projectsIsLoading || filtersIsLoading ? (_jsx(Loading, {})) : projects.length > 0 ? (_jsx(React.Fragment, { children: _jsx(motion.div, __assign({ initial: { opacity: 0 }, animate: { opacity: 1 }, transition: { duration: 0.3 }, exit: { opacity: 0 } }, { children: projects.map(function (project, i) {
                return (_jsx(ProjectsCard, { item: project }, i));
            }) })) })) : (_jsx(React.Fragment, { children: _jsx(NotFound, {}) }));
};
