var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import MarketsContext from '../../../application/Context/MarketsContext';
import ProjectsContext from '../../../application/Context/ProjectsContext';
export var InfoBar = function (_a) {
    var application = _a.application;
    var context = application == "markets" ? React.useContext(MarketsContext) : React.useContext(ProjectsContext);
    var count = context.count;
    var t = useTranslation(['news']).t;
    return (_jsx("div", __assign({ className: "under-filter-info" }, { children: _jsxs("div", { children: [count, " ", t("éléments")] }) })));
};
