import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from "react";
import { List } from "./List";
import { TimeLine } from "./TimeLine";
import ProjectsContext from "../../../../application/Context/ProjectsContext";
import MarketsContext from "../../../../application/Context/MarketsContext";
export var View = function (_a) {
    var application = _a.application;
    var context = application == "markets" ? useContext(MarketsContext) : useContext(ProjectsContext);
    var view = context.view;
    return (_jsx(React.Fragment, { children: view === "list" ? _jsx(List, { application: application }) : _jsx(TimeLine, { application: application }) }));
};
