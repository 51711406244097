var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from "react";
import { Title } from "../../shared-components/Title/Title";
import { useTranslation } from "react-i18next";
import ProjectsContext from "../../../../application/Context/ProjectsContext";
import MarketsContext from "../../../../application/Context/MarketsContext";
export var ViewToggler = function (_a) {
    var application = _a.application;
    var t = useTranslation(["common"]).t;
    var context = application == "markets" ? useContext(MarketsContext) : useContext(ProjectsContext);
    var view = context.view, handleView = context.handleView;
    return (_jsx(React.Fragment, { children: _jsxs("div", __assign({ className: "d-flex flex-wrap justify-content-between", style: { alignItems: 'baseline' } }, { children: [_jsx(Title, { title: application == "markets" ? t("Avis des donneurs d'ordres") : t("Avis d'appels d'offres publics") }), _jsxs("div", __assign({ className: "d-flex gap-2" }, { children: [_jsx("div", __assign({ onClick: handleView, style: { width: '24px', height: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center' } }, { children: _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/f-list").concat(view == "list" ? "-active" : "", ".svg"), alt: "list icon" }) })), _jsx("div", __assign({ onClick: handleView, style: { width: '24px', height: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center' } }, { children: _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/calendar-full").concat(view == "calendar" ? "-b" : "-g", ".svg"), alt: "calendar icon" }) }))] }))] })) }));
};
